import React, { useEffect, useState } from 'react';
// import { fireStore } from '../firebase';
import { auth } from '../firebase';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {

    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        auth.onAuthStateChanged(setCurrentUser)
    }, []);

    useEffect(() => {
        if (currentUser) {
            console.log(currentUser);
        }
    }, [currentUser])


    let logout = () => {
        auth.signOut();
        setCurrentUser(null)
    }

    return (
        <AuthContext.Provider
            value={{
                currentUser, 
                logout, 
            }}
            class='context' >
            {children}
        </AuthContext.Provider>
    );
};