import React, { useState, useEffect, lazy, Suspense } from 'react';
import { AuthProvider } from './context/Context';
import { auth } from '../src/firebase';
import {  BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import logo from './images/wiseeuslogo-02w-01.png';

import './App.less';

const Login = lazy(() => import('./components/login/Login'))
const UserHome = lazy(() => import('./components/userHome/UserHome'))
const Report = lazy(() => import('./components/report/Report'))
const ReportReceived = lazy(() => import('./components/received/ReportReceived'))
const WriterInfo = lazy(() => import('./components/info/WriterInfo'))
const FourZeroFour = lazy(() => import('./components/404/FourZeroFour'))

function App() {     

  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    auth.onAuthStateChanged((newUser) => {
      setUser(newUser)
      newUser ? console.log('user logged in') : console.log('no user')
      setLoading(false)
    })
  }, [])

  function PrivateRoute({ children }) {
    return user ? children : <Navigate to="/login" />;
  }

  return (
      <AuthProvider>
          <Suspense fallback={<div><img style={{marginTop: '2em'}} className="reportPageLogo" src={logo} alt="WISE Logo"/></div>}>
              <Router>
                  {loading ? <div><img style={{marginTop: '2em'}} className="reportPageLogo" src={logo} alt="WISE Logo"/></div> :
                      <Routes>
                          <Route path='/' element={<UserHome />} />
                        
                          <Route path='/report' element={<Report />} />
                          <Route path='/report-received' element={<ReportReceived />} />
                          <Route path='*' element={<FourZeroFour />} />
                      </Routes>
                  }
              </Router>
          </Suspense>
      </AuthProvider>
  );
}

 export default App;



//  {
//     "projects": {
//       "default": "wise-reporting"
//     },
//     "targets": {},
//     "etags": {
//       "wise-reporting": {
//         "extensionInstances": {
//           "firestore-send-email": "159ee31a6f3f06cef0bafcc76bb35439c0bbaaa3e15408508f6dd832c25f044d"
//         }
//       }
//     }
//   }
  
  
//   // .firebaserc